import { PrimaryButton } from '../buttons/primary-button';
import { SecondaryButton } from '../buttons/secondary-button';
import { useEffect, useState } from 'react';
import { CustomModal } from '../modals/customModal';
import { cashbackWithdrawalAPI, getCashbackBalanceAPI } from '../../services/cashback';
import { ErrorBubble } from '../utils/errorBubble';
import Loader from '../../pages/utils/loader';
import { useTranslation } from 'react-i18next';

export const CashbackComponent = () => {
	const { t } = useTranslation();

	const [isMoreDetailsModalOpen, setMoreDetailsModalOpen] = useState(false);
	const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

	const [balance, setBalance] = useState(0);

	const [errMsg, setErrMsg] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getCashbackBalance = async () => {
			const response = await getCashbackBalanceAPI();
			if (response.isSuccess) {
				setBalance(response.data);
			}
		};

		getCashbackBalance();
	}, []);

	return (
		<div className="border border-darkGray rounded-[10px] mt-2 flex">
			<div className="bg-red1 font-bolder text-center p-2 flex-grow">
				<p className="text-xl">{t('pages.dashboard.cashback.title')}</p>
				<div className="flex justify-center">
					<img src="/rainbow_favicon.svg" className="w-[20px]" />
					<p>{t('pages.dashboard.cashback.paytora')}</p>
				</div>
				<div className="flex items-center justify-center">
					<div className="text-center">
						<p className="text-[3rem]">{balance.toFixed(2)}</p>
					</div>
					<div className="flex flex-col items-center">
						<img src="/rainbow_favicon.svg" className="w-[24px]" />
						<p>{t('pages.dashboard.cashback.ptc')}</p>
					</div>
				</div>
				<p>({t('pages.dashboard.cashback.paytoraCoin')})</p>
				<div>
					<PrimaryButton
						text={t('buttons.withdraw')}
						className={`${balance < 10 ? 'bg-darkGray' : 'bg-purple'}  rounded-[18px]`}
						textColor="text-white"
						onClick={() => setIsWithdrawModalOpen(true)}
						disabled={balance < 10}
					/>
					<p className="text-md">
						{t('pages.dashboard.cashback.minimum')} <br /> {t('pages.dashboard.cashback.minimumAmount')}
					</p>
					<SecondaryButton
						text={t('buttons.moreDetails')}
						textColor="text-darkGray"
						className="text-md"
						onClick={() => setMoreDetailsModalOpen(true)}
					/>
				</div>
			</div>

			<img src="/assets/cashback_side.png" alt="cashback" className="pe-2" />

			<CustomModal
				isOpen={isWithdrawModalOpen}
				onClose={() => setIsWithdrawModalOpen(false)}
				title={t('modals.cashback.withdraw.title')}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							{/* <p>You are about to withdraw {balance.toFixed(4)}</p> */}
							<p>
								{t('modals.cashback.withdraw.content')} <br /> {t('modals.cashback.withdraw.secondContent')}
							</p>

							<div className="flex flex-col justify-center mt-[20px]">
								<PrimaryButton
									onClick={async () => {
										try {
											setIsLoading(true);
											const response = await cashbackWithdrawalAPI();

											if (!response) return setErrMsg(response.error);

											setIsWithdrawModalOpen(false);
										} catch (error) {
											console.log(error);
										} finally {
											setIsLoading(false);
											window.location.reload();
										}
									}}
									text={t('buttons.withdraw')}
									textColor="text-white"
								/>
								<SecondaryButton
									onClick={() => {
										setIsWithdrawModalOpen(false);
									}}
									text={t('buttons.close')}
									textColor="text-black"
								/>
							</div>
							{errMsg && <ErrorBubble error={errMsg} />}
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={isMoreDetailsModalOpen}
				onClose={() => setMoreDetailsModalOpen(false)}
				title={t('modals.cashback.moreDetails.title')}
				children={
					<div>
						<p>{t('modals.cashback.moreDetails.content')}</p>

						<div className="flex flex-col justify-center mt-[20px]">
							<PrimaryButton
								onClick={() => {
									setMoreDetailsModalOpen(false);
								}}
								text={t('buttons.close')}
								textColor="text-white"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
};
