import { useDispatch, useSelector } from 'react-redux';

import { AccountBalance } from '../../components/dashboard/accountBalance';
import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';

import { fetchUserAccounts } from '../../store/accountsSlice';
import { useEffect, useState } from 'react';
import Loader from '../utils/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDashboardInformation } from '../../services/user';
import { CardsBalance } from '../../components/dashboard/cardsBalance';
import { IbanBalance } from '../../components/dashboard/ibanBalance';
import { GradientButton } from '../../components/buttons/gradient_button';
import { useTranslation } from 'react-i18next';

export const Accounts = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const { userWallets, loading } = useSelector((state) => state.accounts);

	const [isLoading, setIsLoading] = useState(true);

	const [cardsBalance, setCardsBalance] = useState(0);
	const [ibanBalance, setIbanBalance] = useState(0);
	const [hasIban, setHasIban] = useState(false);
	// const [hasCard, setHasCards] = useState(false);

	const location = useLocation();
	const { isMerchant } = location.state || {};

	useEffect(() => {
		dispatch(fetchUserAccounts());
	}, [dispatch]);

	useEffect(() => {
		const loadDashboard = async () => {
			const dashboardResponse = await getDashboardInformation();
			setCardsBalance(dashboardResponse.data.cardsTotalBalance);
			setIbanBalance(dashboardResponse.data.ibanTotalBalance);
			setHasIban(dashboardResponse.data.hasIBAN);
			// setHasCards(dashboardResponse.data.hasCards);

			setIsLoading(false);
		};

		loadDashboard();
	}, []);

	return loading || isLoading ? (
		<Loader />
	) : (
		<div className="p-5">
			<Header title={t('pages.balances.title')} />
			<AccountBalance userWallets={userWallets} />
			{!isMerchant && <CardsBalance cardsBalance={cardsBalance} />}
			{!isMerchant && <IbanBalance ibanBalance={ibanBalance} hasIban={hasIban} />}
			<GradientButton
				text={t('buttons.swapCurrencies')}
				onClick={() => navigate('/swap-currency')}
				iconPath="icons/button_swap_currencies_black.svg"
				className="mt-2"
			/>
			<div className="flex justify-center mt-10">
				<PageLogo />
			</div>
		</div>
	);
};
