import { apiRequest } from './api';

export const getCashbackBalanceAPI = async () => {
	try {
		const response = await apiRequest('/user/cashback/balance', 'GET');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};

export const cashbackWithdrawalAPI = async () => {
	try {
		const response = await apiRequest('/user/cashback/withdraw', 'POST');

		return { isSuccess: true, data: response.data };
	} catch (error) {
		return { isSuccess: false, error: error.message };
	}
};
