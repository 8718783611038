import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import numeral from 'numeral';

import { getDecodedToken } from '../../utils/functions/token';
import { PrimaryButton } from '../buttons/primary-button';
import { useNavigate } from 'react-router-dom';

export const AccountDetails = ({ totalBalance, isMerchant = false }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const langDir = localStorage.getItem('languageDir');

	const [accountNumber, setAccountNumber] = useState('');
	const [fullName, setFullName] = useState('');

	useEffect(() => {
		const decodedToken = getDecodedToken();

		setAccountNumber(decodedToken?.customerNumber ?? '');
		setFullName(decodedToken?.fullName ?? '');
	}, []);

	return (
		<div>
			<div className="mb-2">
				<p className="mb-2 font-bold" dir={langDir}>
					{t('pages.dashboard.welcome')}, {fullName}
				</p>
				<div className="flex items-center justify-between" dir={langDir}>
					<p className="text-sm">{t('pages.dashboard.accountDetails.paytoraCustomer')}</p>
					<h1 className="text-lg font-bolder ">{accountNumber}</h1>
				</div>
			</div>

			<div dir={langDir} className="border border-darkGray rounded-[10px] bg-white p-3 flex justify-between items-center">
				<div>
					<h1 className="text-[23px]">€{numeral(totalBalance).format('0,0.00')}</h1>
					<p className="text-sm text-darkGray">{t('pages.dashboard.accountDetails.totalBalance')}</p>
				</div>

				<PrimaryButton
					text={t('buttons.accountsAndBalances')}
					textColor="text-white"
					className="!h-[2.4rem] !w-[48%] !text-[14px] font-bolder"
					onClick={() => navigate('/balances', { state: { isMerchant } })}
				/>
			</div>
		</div>
	);
};
