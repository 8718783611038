import { useTranslation } from 'react-i18next';

import { Header } from '../../../../../components/navigation/header';

import { AccountDetailsSubHeader } from '../../../../../components/navigation/accountDetailsSubHeader';
import SelectInput from '../../../../../components/inputs/selectInput';
import { Input } from '../../../../../components/inputs/textInput';

const instantCurrencies = [
	{ label: '🇰🇭 KHR - CAMBODIAN RIEL', value: 'KHR' },
	{ label: '🇨🇳 CNY - YUAN RENMINBI (CHINA)', value: 'CNY' },
	{ label: '🇧🇩 BDT - BANGLADESHI TAKKA', value: 'BDT' },
	{ label: '🇮🇳 INR - INDIAN RUPEE', value: 'INR' },
	{ label: '🇮🇩 IDR - INDONESIAN RUPIAH', value: 'IDR' },
	{ label: '🇯🇵 JPY - JAPANESE YEN', value: 'JPY' },
	{ label: '🇲🇾 MYR - MALAYSIAN RINGGIT', value: 'MYR' },
	{ label: '🇵🇭 PHP - PHILIPPINE PESO', value: 'PHP' },
	{ label: '🇰🇷 KRW - SOUTH KOREAN WON', value: 'KRW' },
	{ label: '🇹🇼 TWD - TAIWANESE DOLLAR', value: 'TWD' },
	{ label: '🇹🇭 THB - THAI BAHT', value: 'THB' },
	{ label: '🇻🇳 VND - VIETNAMESE DONG', value: 'VND' },

	{ label: '🇧🇼 BWP - BOTSWANAN PULA', value: 'BWP' },
	{ label: '🇨🇫 XAF - CENTRAL AFRICAN CFA FRANC', value: 'XAF' },
	{ label: '🇨🇩 CDF - CONGOLESE FRANC (DRC)', value: 'CDF' },
	{ label: '🇬🇭 GHS - GHANAIAN CEDI', value: 'GHS' },
	{ label: '🇰🇪 KES - KENYAN SHILLING', value: 'KES' },
	{ label: '🇲🇼 MWK - MALAWIAN KWACHA', value: 'MWK' },
	{ label: '🇳🇬 NGN - NIGERIAN NAIRA', value: 'NGN' },
	{ label: '🇷🇼 RWF - RWANDAN FRANC', value: 'RWF' },
	{ label: '🇿🇦 ZAR - SOUTH AFRICAN RAND', value: 'ZAR' },
	{ label: '🇦🇪 AED - UNITED ARAB EMIRATES DIRHAM', value: 'AED' },
	{ label: '🇺🇬 UGX - UGANDAN SHILLING', value: 'UGX' },
	{ label: '🇫🇷 XOF - WEST AFRICAN CFA FRANC', value: 'XOF' },
	{ label: '🇿🇲 ZMW - ZAMBIAN KWACHA', value: 'ZMW' },

	{ label: '🇦🇺 AUD - AUSTRALIAN DOLLAR', value: 'AUD' },
	{ label: '🇨🇦 CAD - CANADIAN DOLLAR', value: 'CAD' },
	{ label: '🇭🇰 HKD - HONG KONG DOLLAR', value: 'HKD' },
	{ label: '🇸🇬 SGD - SINGAPORE DOLLAR', value: 'SGD' },
	{ label: '🇬🇧 GBP - GREAT BRITISH POUND', value: 'GBP' },
	{ label: '🇺🇸 USD - UNITED STATES DOLLAR', value: 'USD' },
];

export default function PaytoraWithdrawalFirstStep({ bankDetails, handleBankDetailsChange, currencyHandler }) {
	const langDir = localStorage.getItem('languageDir');

	const { t } = useTranslation();

	return (
		<>
			<div dir={langDir}>
				<Header title={t('pages.withdrawal.header.paytora')} />
				<AccountDetailsSubHeader />
				<h1 className="text-lg mb-[10px]">{t('pages.withdrawal.step2.title')}</h1>

				<p className="mt-5">Choose currency to send</p>
				<SelectInput
					defaultValue={{
						value: instantCurrencies[0].value,
						label: instantCurrencies[0].label,
					}}
					onChange={(value) => currencyHandler(value)}
					options={instantCurrencies.map((opt) => ({
						value: opt.value,
						label: opt.label,
					}))}
				/>

				<div>
					<p className="mt-5">{t('pages.withdrawal.step2.label.iban')}</p>
					<Input
						onChange={(value) => handleBankDetailsChange('receiverIBAN', value)}
						placeholder="Ex. TJ221002P"
						type="text"
						value={bankDetails.receiverIBAN}
					/>

					<p>{t('pages.withdrawal.step2.label.fullName')}</p>
					<Input
						onChange={(value) => handleBankDetailsChange('receiverFullName', value)}
						placeholder="Ex. John Doe"
						type="text"
						value={bankDetails.receiverFullName}
					/>
					<p>{t('pages.withdrawal.step2.label.bankName')}</p>
					<Input
						onChange={(value) => handleBankDetailsChange('receiverBankName', value)}
						placeholder="Ex. Bank Of China"
						type="text"
						value={bankDetails.receiverBankName}
					/>
					<label htmlFor="branch">{t('pages.withdrawal.step2.label.bankBranch')}</label>
					<Input
						key="branch"
						onChange={(value) => handleBankDetailsChange('receiverBankBranch', value)}
						placeholder="Ex. Shenzhen Branch"
						type="text"
						value={bankDetails.receiverBankBranch}
					/>
				</div>
			</div>
		</>
	);
}
