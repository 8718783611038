import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { fetchUserAccounts } from '../../store/accountsSlice';

import Median from 'median-js-bridge';

import Loader from '../utils/loader';

import { getDashboardInformation, getUserStatuses, getUserTransactionsAPI } from '../../services/user';

import { AccountDetails } from '../../components/dashboard/accountDetails';
import { CardsBalance } from '../../components/dashboard/cardsBalance';
import { IbanBalance } from '../../components/dashboard/ibanBalance';
import { DashboardActions } from '../../components/dashboard/dashboardActions';

import { BottomMenu } from '../../components/navigation/bottomMenu';
import { DashboardHeader } from '../../components/navigation/dashboardHeader';

import { TransactionsContainer } from '../../components/transactions/transactionsContainer';
import { CustomModal } from '../../components/modals/customModal';
import { PrimaryButton } from '../../components/buttons/primary-button';

import { CashbackComponent } from '../../components/dashboard/cashback';
import { useIbanStatus } from '../../hooks/useIbanStatus';

export default function Dashboard() {
	const dispatch = useDispatch();
	const { totalBalance, loading } = useSelector((state) => state.accounts);

	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);

	const [isMerchant, setIsMerchant] = useState(false);

	const [isAppDownloadModalOpen, setIsAppDownloadModalOpen] = useState(false);

	const [transactions, setTransactions] = useState();

	const { isLoading: ibanIsLoading, hasIban } = useIbanStatus();

	useEffect(() => {
		dispatch(fetchUserAccounts());
	}, [dispatch]);

	useEffect(() => {
		setIsAppDownloadModalOpen(!Median.isNativeApp());
	}, []);

	useEffect(() => {
		const userStatuses = async () => {
			if (!isLoading) {
				const response = await getUserStatuses();
				setIsMerchant(response.data.userType === 'merchant');
			}
		};

		userStatuses();
	}, []);

	useEffect(() => {
		const getTransactionsByType = async () => {
			setIsLoading(true);
			const response = await getUserTransactionsAPI('all');
			setTransactions(response.data);

			setIsLoading(false);
		};

		getTransactionsByType();
	}, []);

	return isLoading || loading || ibanIsLoading ? (
		<Loader />
	) : (
		<div className="bg-lighterGray p-5 pb-[100px]">
			<DashboardHeader />

			<div className="relative z-10">
				<AccountDetails totalBalance={totalBalance} isMerchant={isMerchant} />
				<CashbackComponent />
				<div className="bg-lightBlack p-[14px] h-[160px] mt-[10px] rounded-[10px]">
					<div className="border border-gold h-full rounded-[6px] p-3">
						<div className="flex justify-between">
							<img src="/assets/icons/erc.svg" alt="erc" className="w-[2.5rem]" />
							<img src="/assets/icons/trc.svg" alt="trc" className="w-[2.5rem]" />
							<img src="/assets/icons/eth.svg" alt="eth" className="w-[2.5rem]" />
							<img src="/assets/icons/btc.svg" alt="btc" className="w-[2.5rem]" />
						</div>

						<p className="text-md text-white font-bolder me-5">{t('pages.dashboard.sell.crypto')}</p>

						<div className="flex justify-between">
							<div></div>
							<a href="/crypto/exchange" className="text-white border border-white p-1 text-sm">
								{t('buttons.pressHere')}
							</a>
						</div>
					</div>
				</div>
			</div>

			{!isMerchant && !hasIban && <IbanBalance ibanBalance={0} hasIban={hasIban} />}
			<DashboardActions isMerchant={isMerchant} />
			<TransactionsContainer transactions={transactions} />

			<BottomMenu isMerchant={isMerchant} />

			<CustomModal
				isOpen={isAppDownloadModalOpen}
				onClose={() => setIsAppDownloadModalOpen(false)}
				title={t('modals.dashboard.application_download.title')}
				subTitle={t('modals.dashboard.application_download.subTitle')}
				children={
					<div>
						<div className="flex justify-center items-center">
							<a href="https://play.google.com/store/apps/details?id=com.paytora.app">
								<img src="/assets/application/google_store.png" className="w-[150px]" alt="google_app" />
							</a>
							<a href="https://apps.apple.com/il/app/paytora-digital-bank/id6612336703?l=he">
								<img src="/assets/application/apple_store.png" className="w-[150px]" alt="apple_app" />
							</a>
						</div>

						<div className="flex flex-col justify-center mt-[20px]">
							<PrimaryButton
								onClick={() => {
									setIsAppDownloadModalOpen(false);
								}}
								text={t('buttons.close')}
								textColor="text-white"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
