import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { Header } from '../../components/navigation/header';
import { PageLogo } from '../../components/utils/pageLogo';
import { useTranslation } from 'react-i18next';

export default function NotFound404() {
	const navigate = useNavigate();

	const { t } = useTranslation();

	return (
		<div className="p-5 h-screen flex flex-col justify-between">
			{/* <HeaderOld showUser={false} showBalance={false} showBack={false} /> */}
			<Header hasIcon={true} hasBack={false} showUserChat={false} />
			<div className="text-center">
				<p className=" text-lg font-bold">
					{t('pages.404.content_first')} <br /> {t('pages.404.content_second')}
				</p>
				<h1 className="text-[100px] text-gradient font-bolder">404</h1>
			</div>
			<PageLogo className="mb-20" />
			<PrimaryButton textColor="text-white" text={t('buttons.returnHome')} onClick={() => navigate('/')} />
		</div>
	);
}
