import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const CardsBalance = ({ cardsBalance, hasCards }) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	return (
		<div onClick={() => navigate('/cards')} dir={langDir}>
			<div className="bg-lightBlack p-[14px] h-[160px] mt-[10px] rounded-[10px]">
				<div className="border border-gold h-full rounded-[6px] p-3">
					<div className="flex justify-between">
						<div className="text-gold">
							<img src="/assets/card/card_logo_gold.svg" />
							<p className="font-bold text-xxl mt-[20px]">{'€' + numeral(cardsBalance).format('0,0.00')}</p>
							<p className="text-sm">{t('pages.dashboard.cardsBalance.total')}</p>
						</div>
						<div className="flex flex-col items-center">
							<img src="/assets/card/card_apple.svg" className="mb-[5px]" />
							<img src="/assets/card/card_google.svg" />
							<img src="/assets/card/card_mastercard.svg" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
