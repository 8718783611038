import { z } from 'zod';

export const step3Validation = (t) =>
	z.object({
		description: z
			.string()
			.min(1)
			.max(140)
			.regex(/^[^\s]+(\s[^\s]+)*$/, 'No leading or trailing spaces allowed'),

		amountMinor: z.number().max(9999999999), // Ensures it doesn't exceed 10 digits

		currency: z
			.string()
			.length(3)
			.regex(/^[A-Z]{3}$/, 'Must be a 3-character'),
	});

export const paytoraWithdrawalSchema = (t) =>
	z.object({
		// wireType: z.string().trim().min(2, t('errors.validations.wireType')),
		receiverFullName: z
			.string()
			.min(3, 'Receiver Full Name is required (minimum 3 characters)')
			.max(70, 'Receiver Full Name must contain at most 70 characters')
			.regex(
				/^[A-Za-z0-9ĆćŁłŹźŚśÓóĄąĘęŃńŻż().,/:;+\-!@#$&*{}\[\]?= ]*$/,
				'Receiver full name must contain only english characters special characters are not allowed (& $ , @ * .)'
			),
		receiverIBAN: z.string().min(1, 'Receiver IBAN is requried').max(34).regex(/^\S+$/, 'Reciever IBAN whitespaces are not allowed.'),

		receiverBankName: z.string().min(2, 'Bank Name is required'),
		receiverBankBranch: z.string().min(2, 'Bank Branch is required'),
	});

export const WithdrawReceiverIBANDetails = (t) =>
	z.object({
		// wireType: z.string().trim().min(2, t('errors.validations.wireType')),
		receiverIBAN: z.string().min(1, 'Receiver IBAN is requried').max(34).regex(/^\S+$/, 'Reciever IBAN whitespaces are not allowed.'),

		receiverFullName: z
			.string('required')
			.min(3, 'Receiver Full Name is required (minimum 3 characters)')
			.max(70, 'Receiver Full Name must contain at most 70 characters')
			.regex(
				/^[A-Za-z0-9ĆćŁłŹźŚśÓóĄąĘęŃńŻż().,/:;+\-!@#$&*{}\[\]?= ]*$/,
				'Receiver full name must contain only english characters special characters are not allowed (& $ , @ * .)'
			),
		receiverBankName: z.string().trim().optional(),
		receiverStreetAndNumber: z
			.string()
			.min(1, 'Receiver street & number is required')
			.max(35, 'Receiver street & number must contain at most 35 characters')
			.regex(
				/^[A-Za-z0-9ĆćŁłŹźŚśÓóĄąĘęŃńŻż./\- ]*$/,
				'Receiver street & number must contain only english characters special characters are not allowed (& $ , @ * .)'
			),
		receiverCity: z.string().trim().min(1, 'Receiver bank city is required'),

		receiverPostalCode: z
			.string()
			.min(1, 'Receiver postal code is required')
			.max(35, 'Receiver postal code must contain at most 35 characters')
			.regex(
				/^[A-Za-z0-9ĆćŁłŹźŚśÓóĄąĘęŃńŻż./\- ]*$/,
				'Receiver postal code  must contain only english characters special characters are not allowed (& $ , @ * .)'
			),
	});

export const withdrawalAccountWithBICDetails = (t) =>
	z.object({
		receiverIBAN: z
			.string()
			.min(1, 'Receiver Account Number is requried')
			.max(34)
			.regex(/^\S+$/, 'Reciever Account Number whitespaces are not allowed.'),

		receiverBIC: z.string().trim().min(1, 'Receiver BIC is required'),

		receiverFullName: z
			.string()
			.min(3, 'Receiver Full Name is required (minimum 3 characters)')
			.max(70, 'Receiver Full Name must contain at most 70 characters')
			.regex(
				/^[A-Za-z0-9ĆćŁłŹźŚśÓóĄąĘęŃńŻż().,/:;+\-!@#$&*{}\[\]?= ]*$/,
				'Receiver full name must contain only english characters special characters are not allowed (& $ , @ * .)'
			),

		receiverStreetAndNumber: z
			.string()
			.min(1, 'Receiver street & number is required')
			.max(35, 'Receiver street & number must contain at most 35 characters')
			.regex(
				/^[A-Za-z0-9ĆćŁłŹźŚśÓóĄąĘęŃńŻż./\- ]*$/,
				'Receiver street & number must contain only english characters special characters are not allowed (& $ , @ * .)'
			),
		receiverCity: z.string().trim().min(1, 'Receiver bank city is required'),

		receiverPostalCode: z
			.string()
			.min(1, 'Receiver postal code is required')
			.max(35, 'Receiver postal code must contain at most 35 characters')
			.regex(
				/^[A-Za-z0-9ĆćŁłŹźŚśÓóĄąĘęŃńŻż./\- ]*$/,
				'Receiver postal code  must contain only english characters special characters are not allowed (& $ , @ * .)'
			),
	});

export const IBANDetailsWithdrawal = (t) =>
	z
		.object({
			wireType: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.wireType') }),
			receiverFullName: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.fullName') }),
			receiverIBAN: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.iban') }),
			receiverStreetAndNumber: z.string().trim().optional(),
			receiverPostalCode: z.string().trim().optional(),
			receiverCity: z.string().trim().optional(),
			receiverBIC: z.string().trim().optional(),
			receiverBankName: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.bankName') }),
			receiverBankBranch: z.string().trim().optional(),
		})
		// Additional fields required if wireType !== 'INSTANT'
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverStreetAndNumber, {
			message: t('errors.validations.streetAndNumber'),
			path: ['receiverStreetAndNumber'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverPostalCode, {
			message: t('errors.validations.postalCode'),
			path: ['receiverPostalCode'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverCity, {
			message: t('errors.validations.city'),
			path: ['receiverCity'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverBIC, {
			message: t('errors.validations.bic'),
			path: ['receiverBIC'],
		})
		// receiverBankBranch required only if wireType === 'INSTANT'
		.refine((data) => data.wireType !== 'INSTANT' || !!data.receiverBankBranch, {
			message: t('errors.validations.bankBranch'),
			path: ['receiverBankBranch'],
		});

export const IbanDataWithdrawSchema = (t) =>
	z
		.object({
			wireType: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.wireType') }),
			receiverFullName: z
				.string()
				.trim()
				.min(2, { message: t('errors.validations.fullName') }),
			receiverIBAN: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.iban') }),
			receiverStreetAndNumber: z.string().trim().optional(),
			receiverPostalCode: z.string().trim().optional(),
			receiverCity: z.string().trim().optional(),
			receiverBIC: z.string().trim().optional(),
			receiverBankName: z
				.string()
				.trim()
				.min(1, { message: t('errors.validations.bankName') }),
			receiverBankBranch: z.string().trim().optional(),
		})
		// Additional fields required if wireType !== 'INSTANT'
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverStreetAndNumber, {
			message: t('errors.validations.streetAndNumber'),
			path: ['receiverStreetAndNumber'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverPostalCode, {
			message: t('errors.validations.postalCode'),
			path: ['receiverPostalCode'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverCity, {
			message: t('errors.validations.city'),
			path: ['receiverCity'],
		})
		.refine((data) => data.wireType === 'INSTANT' || !!data.receiverBIC, {
			message: t('errors.validations.bic'),
			path: ['receiverBIC'],
		})
		// receiverBankBranch required only if wireType === 'INSTANT'
		.refine((data) => data.wireType !== 'INSTANT' || !!data.receiverBankBranch, {
			message: t('errors.validations.bankBranch'),
			path: ['receiverBankBranch'],
		});
