import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiOutlineEye } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

import numeral from 'numeral';

import Loader from '../../pages/utils/loader';

import { formatCardNumber } from '../../utils/functions/general';

import { SecondaryButton } from '../buttons/secondary-button';
import { PrimaryButton } from '../buttons/primary-button';

import { CustomModal } from '../modals/customModal';
import { PincodeModal } from '../modals/pincodeModal';

import {
	activatePhysicalCard,
	changePhysicalCardPin,
	freezeVirtualCardAPI,
	getUserCardData,
	terminateVirtualCard,
	unFreezeVirtualCard,
} from '../../services/cards';
import { PincodeInput } from '../inputs/pincodeInput';
import { getDecodedToken } from '../../utils/functions/token';

export const CardItem = ({ card, showActions = true }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const [cvvValues, setCvvValues] = useState(Array(3).fill(''));

	const [lastSixDigitsValues, setLastSixDigitsValues] = useState(Array(6).fill(''));

	const [cardPinCodeValues, setCardPinCodeValues] = useState(Array(4).fill(''));

	const [kind, setKind] = useState(card.cardTemplate.kind);

	const [action, setAction] = useState('freeze'); // freeze, cancel, show

	const [isLoading, setIsLoading] = useState(false);

	const [showCardData, setShowCardData] = useState(false);

	const [cardData, setCardData] = useState(null);

	const [errMsg, setErrMsg] = useState('');

	const decodedToken = getDecodedToken();

	// const [cardPin, setCardPin] = useState('');

	const [modalState, setModalState] = useState({
		isFreezeModalOpen: false,
		isCancelModalOpen: false,
		isShowModalOpen: false,
		isPincodeModalOpen: false,
		isActivateModalOpen: false,
		isSuccessModalOpen: false,
		isChangePinModalOpen: false,
		isSuccessPinChangeOpen: false,
	});

	const handleModalStateChange = (key, value) => {
		setModalState((prevState) => ({
			...prevState,
			[key]: value,
		}));
	};

	const [cardDesign, setCardDesign] = useState({
		bgColor: 'bg-lightBlack',
		borderColor: 'border-gold',
		balanceTextColor: 'text-white',
		limitTextColor: 'text-darkGold',
		cardDetailsTextColor: 'text-white',
		logoPath: 'card_logo_gold.svg',
	});

	const getCardDesignByKind = () => {
		if (kind === 'premium') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-brown',
				borderColor: 'border-darkBrown',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
				// add more properties as needed
			}));
		}
		if (kind === 'kids_blue') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-darkBlue',
				borderColor: 'border-white',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
				// add more properties as needed
			}));
		}

		if (kind === 'kids_silver') {
			setCardDesign((prevCardDesign) => ({
				...prevCardDesign,
				bgColor: 'bg-silver',
				borderColor: 'border-white',
				limitTextColor: 'text-white',
				logoPath: 'card_logo_white.svg',
				// add more properties as needed
			}));
		}
	};

	const showCardNumberHandler = async (pincode) => {
		if (showCardData) return setShowCardData(false);

		if (!isLoading) {
			setIsLoading(true);
			const response = await getUserCardData(card._id, pincode);
			if (response.isSuccess) {
				setCardData(response.card);
				setShowCardData(!showCardData);

				setTimeout(() => {
					setShowCardData(false);
				}, 30000);
			}
		}
		setIsLoading(false);
	};

	const drawDepositButton = () =>
		card.status === 'A' && (
			<SecondaryButton
				text={t('buttons.deposit')}
				textColor="text-black"
				className="!border !border-black w-full mt-2 !rounded-[10px] me-[10px]"
				onClick={() => navigate('/card/deposit', { state: { cardId: card._id } })}
			/>
		);

	const drawCardActionsButtons = () => {
		return (
			<div className="flex justify-between">
				{card.status === 'A' && (
					<SecondaryButton
						text={t('buttons.freezeCard')}
						textColor="text-black"
						className="!border !border-black w-full mt-2 !rounded-[10px] me-[10px]"
						onClick={() => {
							setAction('freeze');
							handleModalStateChange('isFreezeModalOpen', true);
						}}
					/>
				)}
				{card.status === 'F' && (
					<SecondaryButton
						text={t('buttons.unfreezeCard')}
						textColor="text-black"
						className="!border !border-black w-full mt-2 !rounded-[10px] me-[10px]"
						onClick={() => {
							setAction('freeze');
							handleModalStateChange('isFreezeModalOpen', true);
						}}
					/>
				)}
				{card.status === 'A' && (
					<SecondaryButton
						text={t('buttons.cancelCard')}
						textColor="text-black"
						className={`!border !border-black w-full mt-2 !rounded-[10px] ${card.type === 'physical' && 'me-[10px]'}`}
						onClick={() => {
							setAction('cancel');
							handleModalStateChange('isCancelModalOpen', true);
						}}
					/>
				)}

				{card.type === 'physical' && card.status === 'A' && (
					<SecondaryButton
						text={t('buttons.changePin')}
						textColor="text-black"
						className="!border !border-black w-full mt-2 !rounded-[10px]"
						onClick={() => {
							setAction('changePin');
							handleModalStateChange('isPincodeModalOpen', true);
						}}
					/>
				)}

				{card.type === 'physical' && card.status === 'P' && (
					<SecondaryButton
						text={t('buttons.activateCard')}
						textColor="text-black"
						className="!border !border-black w-full mt-2 !rounded-[10px]"
						onClick={() => {
							setAction('activate');
							handleModalStateChange('isActivateModalOpen', true);
						}}
					/>
				)}
			</div>
		);
	};

	const activatePhysicalCardHandler = async () => {
		const cvv = cvvValues.join('');
		const lastDigits = lastSixDigitsValues.join('');

		if (lastDigits.length < 6 || cvv.length < 3) return setErrMsg(t('errors.validations.physicalActivation'));

		if (!isLoading) {
			setIsLoading(true);
			const response = await activatePhysicalCard(cvv, lastDigits);

			if (!response.isSuccess) {
				setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			} else {
				handleModalStateChange('isActivateModalOpen', false);
				handleModalStateChange('isSuccessModalOpen', true);
			}
		}

		setIsLoading(false);
	};

	useEffect(() => {
		getCardDesignByKind();

		if (card.cardTemplate.kind === 'kids_blue' || card.cardTemplate.kind === 'kids_silver') setKind('kids');
	}, []);

	return isLoading ? (
		<Loader height="h-full" />
	) : (
		<div>
			{showActions && (
				<div className="flex justify-between">
					<p className="text-md mb-[10px] mt-[10px]">
						{t('pages.cards.card.item.cardType')} {t(`pages.cards.card.item.kinds.${kind}`)}
					</p>
					<p className="text-md mb-[10px] mt-[10px] font-bold" onClick={() => navigate('/transactions/cards', { state: { card } })}>
						{t('pages.cards.card.item.seeTransactions')}
					</p>
				</div>
			)}
			<div className={`${cardDesign.bgColor} p-2`}>
				<div className={`border ${cardDesign.borderColor} h-full p-5`}>
					<div className="flex items-start justify-between">
						<div className={`${cardDesign.balanceTextColor} text-sm`}>
							<p> {t('pages.cards.card.item.balance')}</p>
							<p className="text-xxl font-bolder">{card.currency.symbol + numeral(card.balance).format('0,0.00')} </p>
							<p className={`${cardDesign.limitTextColor}`}>
								{t('pages.cards.card.item.limit')} {card.currency.symbol + numeral(card.cardTemplate.limit).format('0,0')}
							</p>
						</div>
						<img src={`/assets/card/${cardDesign.logoPath}`} />
					</div>
					<div className="flex items-start justify-between">
						<div className={`${cardDesign.cardDetailsTextColor} text-md mt-[25px]`}>
							<div className="flex items-center" dir="ltr">
								<p className="text-lg font-bolder">{showCardData ? formatCardNumber(cardData.pan) : formatCardNumber(card.pan)}</p>
								{card.status === 'A' && (
									<HiOutlineEye
										className="h-[24px] w-[24px] ms-[10px]"
										onClick={() => {
											setAction('showCard');
											handleModalStateChange('isPincodeModalOpen', true);
										}}
									/>
								)}
							</div>
							{showCardData && <p>CVV {cardData.cvv}</p>}
							<p>
								{t('pages.cards.card.item.exp')} {card.expiration}
							</p>
							<p className="font-bold text-lg mt-2">{decodedToken.fullName.toUpperCase()}</p>
						</div>
						<div className="flex flex-col justify-between">
							<img src="/assets/card/card_apple.svg" className="mb-[5px]" />
							<img src="/assets/card/card_google.svg" />
							<img src="/assets/card/card_mastercard.svg" />
						</div>
					</div>
				</div>
			</div>

			{drawDepositButton()}

			{showActions && drawCardActionsButtons()}

			<CustomModal
				isOpen={modalState.isFreezeModalOpen}
				onClose={() => handleModalStateChange('isFreezeModalOpen', false)}
				title={t('modals.cards.title')}
				subTitle={`${t('modals.cards.freeze.subTitle.first')} ${
					card.status === 'A' ? t('modals.cards.freeze.subTitle.freeze') : t('modals.cards.freeze.subTitle.unfreeze')
				} ${t('modals.cards.freeze.subTitle.second')}`}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<div className="flex flex-col justify-center mt-[20px]">
								<PrimaryButton
									onClick={() => handleModalStateChange('isPincodeModalOpen', true)}
									text={`${card.status === 'A' ? t('buttons.freeze') : t('buttons.unfreeze')}`}
									textColor="text-white"
								/>
								<SecondaryButton
									onClick={() => {
										handleModalStateChange('isFreezeModalOpen', false);
									}}
									text={t('buttons.close')}
									textColor="text-black"
								/>
							</div>
							<p className="text-red text-center mt-2">{errMsg}</p>
						</div>
					)
				}
			/>
			<CustomModal
				isOpen={modalState.isCancelModalOpen}
				onClose={() => handleModalStateChange('isCancelModalOpen', false)}
				title={t('modals.cards.title')}
				subTitle={t('modals.cards.cancel.subTitle')}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<div className="flex flex-col justify-center mt-[20px]">
								<PrimaryButton
									onClick={async () => handleModalStateChange('isPincodeModalOpen', true)}
									text={t('buttons.verify')}
									textColor="text-white"
								/>
								<SecondaryButton
									onClick={() => {
										handleModalStateChange('isCancelModalOpen', false);
									}}
									text={t('buttons.close')}
									textColor="text-black"
								/>
							</div>
							<p className="text-red text-center mt-2">{errMsg}</p>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={modalState.isChangePinModalOpen}
				onClose={() => handleModalStateChange('isChangePinModalOpen', false)}
				title={t('modals.cards.title')}
				subTitle={t('modals.cards.changePin.subTitle')}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<div className="flex flex-col justify-center mt-[20px]">
								<PincodeInput onChange={(value) => setCardPinCodeValues(value)} pincode={cardPinCodeValues} className="mb-[20px]" />
								<p className="mb-2 text-red">{t('modals.cards.apply.physical.addtional.fee.first')}2</p>
								<PrimaryButton
									onClick={async () => {
										if (!isLoading) {
											setIsLoading(true);
											if (cardPinCodeValues.join('').length !== 4) {
												setIsLoading(false);
												return setErrMsg('Pincode required to be 4 length');
											}
											const response = await changePhysicalCardPin(card._id, cardPinCodeValues.join(''));
											if (response.isSuccess) {
												handleModalStateChange('isChangePinModalOpen', false);
												handleModalStateChange('isSuccessPinChangeOpen', true);
											} else {
												setErrMsg(response.error);
											}
										}
										setIsLoading(false);
									}}
									text={t('buttons.changePin')}
									textColor="text-white"
								/>
								<SecondaryButton
									onClick={() => {
										handleModalStateChange('isChangePinModalOpen', false);
									}}
									text={t('buttons.close')}
									textColor="text-black"
								/>
							</div>
							<p className="text-red text-center mt-2">{errMsg}</p>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={modalState.isActivateModalOpen}
				onClose={() => handleModalStateChange('isActivateModalOpen', false)}
				title={t('modals.cards.active.physical.title')}
				subTitle={t('modals.cards.active.physical.subTitle')}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							{t('modals.cards.active.physical.label.cvv')}
							<PincodeInput onChange={(value) => setCvvValues(value)} pincode={cvvValues} className="mb-[20px]" />
							{t('modals.cards.active.physical.label.cardDigits')}
							<PincodeInput onChange={(value) => setLastSixDigitsValues(value)} pincode={lastSixDigitsValues} className="mb-[20px]" />
							<div className="text-center mt-5">
								<PrimaryButton
									onClick={async () => {
										await activatePhysicalCardHandler();
									}}
									text={t('buttons.activate')}
									textColor="text-white"
								/>
								<SecondaryButton
									onClick={() => {
										handleModalStateChange('isActivateModalOpen', false);
									}}
									text={t('buttons.close')}
									textColor="text-black"
								/>
								<p className="text-red text-center mt-5">{errMsg}</p>
							</div>
						</div>
					)
				}
			/>
			<PincodeModal
				isOpen={modalState.isPincodeModalOpen}
				onClose={() => handleModalStateChange('isPincodeModalOpen', false)}
				onAction={async (pincode) => {
					if (action === 'freeze') {
						if (!isLoading) {
							setIsLoading(true);

							const response = card.status === 'A' ? await freezeVirtualCardAPI(card._id, pincode) : await unFreezeVirtualCard(card._id, pincode);
							if (response.isSuccess) {
								handleModalStateChange('isFreezeModalOpen', false);
								window.location.reload();
							} else {
								setErrMsg(response.error);
							}
						}
						setIsLoading(false);
					}
					if (action === 'showCard') await showCardNumberHandler(pincode);

					if (action === 'cancel') {
						if (!isLoading) {
							setIsLoading(true);
							const response = await terminateVirtualCard(card._id, pincode);
							if (response.isSuccess) {
								handleModalStateChange('isCancelModalOpen', false);
								window.location.reload();
							} else {
								setErrMsg(response.error);
							}
						}
						setIsLoading(false);
					}

					if (action === 'changePin') handleModalStateChange('isChangePinModalOpen', true);

					handleModalStateChange('isPincodeModalOpen', false);
				}}
			/>
			<CustomModal
				isOpen={modalState.isSuccessModalOpen}
				onClose={() => handleModalStateChange('isSuccessModalOpen', false)}
				title={t('modals.cards.active.virtual.title')}
				subTitle={t('modals.cards.active.virtual.subTitle')}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<div className="text-center">
								<PrimaryButton
									onClick={async () => {
										handleModalStateChange('isSuccessModalOpen', false);
										window.location.reload();
									}}
									text={t('buttons.close')}
									textColor="text-white"
								/>
							</div>
						</div>
					)
				}
			/>

			<CustomModal
				isOpen={modalState.isSuccessPinChangeOpen}
				onClose={() => handleModalStateChange('isSuccessPinChangeOpen', false)}
				title={t('modals.cards.changePin.success.title')}
				subTitle={t('modals.cards.changePin.success.subTitle')}
				children={
					isLoading ? (
						<Loader />
					) : (
						<div>
							<div className="text-center">
								<PrimaryButton
									onClick={async () => {
										handleModalStateChange('isSuccessPinChangeOpen', false);
										window.location.reload();
									}}
									text={t('buttons.close')}
									textColor="text-white"
								/>
							</div>
						</div>
					)
				}
			/>
		</div>
	);
};
