import { useState } from 'react';

import { PrimaryButton } from '../../../../../components/buttons/primary-button';

import IBANWithdrawalFirstStep from './firstStep';
import IBANWithdrawalSecondStep from './secondStep';
import IBANWithdrawalThirdStep from './thirdStep';

import { getDetailsByIbanAPI, withdrawalAPI } from '../../../../../services/ibans';
import { useTranslation } from 'react-i18next';
import { ErrorBubble } from '../../../../../components/utils/errorBubble';
import useValidation from '../../../../../hooks/useValidation';
import { withdrawalAccountWithBICDetails, WithdrawReceiverIBANDetails } from '../../../../../utils/validations/withdraw/withdraw-schema';
import { PageLogo } from '../../../../../components/utils/pageLogo';

import { CustomModal } from '../../../../../components/modals/customModal';
import { PincodeModal } from '../../../../../components/modals/pincodeModal';
import Loader from '../../../../utils/loader';
import { forbiddenWords } from '../paytora/paytoraWithdrawal';

export default function IBANWithdrawalPage() {
	const { t } = useTranslation();

	const [accountType, setAccountType] = useState('iban');

	const { validationErrMsg, validate } = useValidation(accountType === 'iban' ? WithdrawReceiverIBANDetails(t) : withdrawalAccountWithBICDetails(t));

	const [step, setStep] = useState(1);

	const [errMsg, setErrMsg] = useState('');

	const [amount, setAmount] = useState(100);
	const [currency, setCurrency] = useState('EUR');
	const [reason, setReason] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [isVerified, setIsVerified] = useState(false);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [bankDetails, setBankDetails] = useState({
		receiverFullName: '',
		receiverIBAN: '',
		receiverStreetAndNumber: '',
		receiverPostalCode: '',
		receiverCity: '',
		receiverBIC: '',
		receiverBankName: '',
		receiverBankBranch: '',
	});

	const getDetailsByIban = async () => {
		if (!bankDetails.receiverIBAN) return setErrMsg(t('errors.validations.iban'));

		const response = await getDetailsByIbanAPI(bankDetails.receiverIBAN);

		if (!response.isSuccess) {
			setBankDetails((data) => ({
				...data,
				receiverStreetAndNumber: '',
				receiverPostalCode: '',
				receiverCity: '',
			}));

			setIsVerified(false);

			return setErrMsg(response.error);
		}

		setErrMsg('');

		setBankDetails((data) => ({
			...data,
			receiverStreetAndNumber: response.data.address ?? '',
			receiverPostalCode: response.data.postalCode ?? '',
			receiverCity: response.data.city ?? '',
			receiverBankName: response.data.bankName ?? '',
			receiverBIC: response.data.bic ?? '',
		}));

		setIsVerified(true);
	};

	const handleBankWire = async (pincode) => {
		const data = {
			bankDetails,
			amount,
			currency,
			reason,
			pincode,
		};

		if (!amount || amount <= 0) return setErrMsg(t('errors.validations.invalidAmount'));

		if (!reason) return setErrMsg(t('errors.validations.reason'));

		if (forbiddenWords.some((word) => bankDetails.receiverFullName.toLowerCase().includes(word)))
			return setErrMsg('We are not supporting this receiver!');

		if (!isLoading) {
			setIsLoading(true);
			const response = await withdrawalAPI(data);

			if (!response.isSuccess) {
				setIsLoading(false);
				return setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			}
		}

		setIsLoading(false);

		setIsSuccessModalOpen(true);
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 h-screen flex flex-col justify-between">
			{/** SECOND STEP */}
			{step === 1 && (
				<>
					<IBANWithdrawalSecondStep
						handleBankDetailsChange={(field, value) => {
							if (field === 'receiverIBAN') setIsVerified(false);
							setBankDetails((data) => ({ ...data, [field]: value }));
						}}
						bankDetails={bankDetails}
						isVerified={isVerified}
						accountType={accountType}
						handleAccountType={(value) => {
							setAccountType(value);
							setIsVerified(false);
							setBankDetails({
								receiverFullName: '',
								receiverIBAN: '',
								receiverStreetAndNumber: '',
								receiverPostalCode: '',
								receiverCity: '',
								receiverBIC: '',
								receiverBankName: '',
								receiverBankBranch: '',
							});
						}}
					/>
					<div className="flex flex-col justify-center">
						<PageLogo />
					</div>
					<div>
						{errMsg && <ErrorBubble error={errMsg} />}
						{validationErrMsg && <ErrorBubble error={validationErrMsg} />}

						<PrimaryButton
							text={!isVerified && accountType === 'iban' ? t('buttons.verifyIban') : t('buttons.next')}
							className="mb-[10px]"
							textColor="text-white"
							onClick={async () => {
								if (!isVerified && accountType === 'iban') return await getDetailsByIban();
								const isValid = validate(bankDetails);
								if (!isValid) return;

								setStep(step + 1);
							}}
						/>
					</div>
				</>
			)}

			{step === 2 && (
				<>
					<IBANWithdrawalThirdStep
						paymentInfo={{ amount, reason, currency }}
						amountHandler={setAmount}
						reasonHandler={setReason}
						currencyHandler={setCurrency}
					/>
					<div className="flex flex-col justify-center">
						<PageLogo />
					</div>
					<div>
						{errMsg && <ErrorBubble error={errMsg} />}
						<PrimaryButton
							text={t('buttons.send')}
							className="mb-[10px]"
							iconPath="icons/arrow_out_white.svg"
							textColor="text-white"
							onClick={() => setIsPincodeModalOpen(true)}
						/>
					</div>
				</>
			)}

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
				}}
				onAction={async (pincode) => {
					await handleBankWire(pincode);
					setIsPincodeModalOpen(false);
				}}
			/>

			<CustomModal
				isOpen={isSuccessModalOpen}
				onClose={() => setIsSuccessModalOpen(false)}
				title={t('modals.withdrawal.title')} //'Your withdraw action has been submitted'
				subTitle={t('modals.withdrawal.subTitle')} //You will receive a notification while process completed
				children={
					<div>
						<p className="pb-5 text-red font-bold">{t('modals.withdrawal.message')} </p>
						<div className="text-center">
							<PrimaryButton
								onClick={async () => {
									setIsSuccessModalOpen(false);
									window.location.reload();
								}}
								text={t('buttons.close')}
								textColor="text-white"
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
