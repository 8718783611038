import { Link } from 'react-router-dom';
import { Header } from '../../components/navigation/header';
import { BottomMenu } from '../../components/navigation/bottomMenu';
import { PrimaryButton } from '../../components/buttons/primary-button';
import { logout } from '../../services/auth';
import { PageLogo } from '../../components/utils/pageLogo';
import { useTranslation } from 'react-i18next';

export default function Menu() {
	const { t } = useTranslation();
	const langDir = localStorage.getItem('languageDir');

	return (
		<div className="flex flex-col justify-between p-5 h-screen pb-[80px]">
			<div>
				<Header title={t('pages.menu.header')} hasBack={false} hasIcon={true} />
				<div dir={langDir}>
					<Link to="/profile" className="text-lg mb-[20px] mt-[40px] block">
						{t('pages.menu.profile')}
					</Link>
					<div className="w-full h-[2px] bg-lightGray mb-[10px]"></div>
					<Link to="/profile/password" className="text-lg mb-[20px] block">
						{t('pages.menu.updatePassword')}
					</Link>
					<div className="w-full h-[2px] bg-lightGray mb-[10px]"></div>
					<Link to="/e-statements" className="text-lg mb-[20px] block">
						<div className="flex justify-between items-center">
							<p>{t('pages.menu.eStatements')}</p>
						</div>
					</Link>
					<div className="w-full h-[2px] bg-lightGray mb-[10px]"></div>
					<Link to="" className="text-lg mb-[20px]">
						<div className="flex justify-between items-center">
							<p>{t('pages.menu.updateKYC')}</p>
							<p className="text-gradient">{t('pages.menu.comingSoon')}</p>
						</div>
					</Link>
				</div>
			</div>
			<div className="flex justify-center">
				<PageLogo />
			</div>
			<div>
				<div className=" mb-[20px]">
					<PrimaryButton text={t('buttons.signout')} textColor="text-white" onClick={logout} />
				</div>
				<BottomMenu selected="more" />
			</div>
		</div>
	);
}
