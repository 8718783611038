import { useTranslation } from 'react-i18next';

import { Header } from '../../../../../components/navigation/header';

import { AccountDetailsSubHeader } from '../../../../../components/navigation/accountDetailsSubHeader';
import { Input } from '../../../../../components/inputs/textInput';

export default function IBANWithdrawalSecondStep({ handleBankDetailsChange, handleAccountType, accountType, bankDetails, isVerified }) {
	const langDir = localStorage.getItem('languageDir');

	const { t } = useTranslation();

	return (
		<>
			<div dir={langDir}>
				<Header title={t('pages.withdrawal.header.iban')} />
				<AccountDetailsSubHeader />
				<h1 className="text-lg mb-[10px]">{t('pages.withdrawal.step2.title')}</h1>
				<div className="flex space-x-2">
					<div>
						<input
							type="radio"
							id="iban"
							name="iban"
							value="iban"
							checked={'iban' === accountType}
							onChange={(e) => handleAccountType(e.target.value)}
							className="form-radio"
						/>
						<label htmlFor="iban" className="text-sm ml-2">
							IBAN
						</label>
					</div>
					<div>
						<input
							type="radio"
							id="bic"
							name="bic"
							value="bic"
							checked={'bic' === accountType}
							onChange={(e) => handleAccountType(e.target.value)}
							className="form-radio"
						/>
						<label htmlFor="bic" className="text-sm ml-2">
							BIC / SWIFT
						</label>
					</div>
				</div>
				{accountType === 'iban' && (
					<div>
						<p className="mt-5">{t('Receiver IBAN Account Number')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverIBAN', value)}
							placeholder="Ex. PL12345678910"
							type="text"
							value={bankDetails.receiverIBAN}
						/>
					</div>
				)}
				{accountType === 'bic' && (
					<div>
						<p className="mt-5">{t('pages.withdrawal.step2.label.iban')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverIBAN', value)}
							placeholder="Ex. TJ221002P"
							type="text"
							value={bankDetails.receiverIBAN}
						/>
						<p>{t('pages.withdrawal.step2.label.bic')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverBIC', value)}
							placeholder="Ex. BCNSSXXXX"
							type="text"
							value={bankDetails.receiverBIC}
						/>
						<p>{t('pages.withdrawal.step2.label.fullName')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverFullName', value)}
							placeholder="Ex. John Doe"
							type="text"
							value={bankDetails.receiverFullName}
						/>

						<p>{t('pages.withdrawal.step2.label.streetAndNumber')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverStreetAndNumber', value)}
							placeholder="Ex. Lublin 123"
							type="text"
							value={bankDetails.receiverStreetAndNumber}
						/>
						<p>{t('pages.withdrawal.step2.label.city')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverCity', value)}
							placeholder="Ex. New York City"
							type="text"
							value={bankDetails.receiverCity}
						/>
						<p>{t('pages.withdrawal.step2.label.postalCode')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverPostalCode', value)}
							placeholder="Ex. 9948838"
							type="text"
							value={bankDetails.receiverPostalCode}
						/>
					</div>
				)}
				{isVerified && accountType === 'iban' && (
					<>
						<p>{t('pages.withdrawal.step2.label.fullName')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverFullName', value)}
							placeholder="Ex. John Doe"
							type="text"
							value={bankDetails.receiverFullName}
						/>
						<p>{t('pages.withdrawal.step2.label.bankName')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverBankName', value)}
							placeholder="Ex. Bank Of China"
							type="text"
							value={bankDetails.receiverBankName}
						/>
						{/* <p>{t('pages.withdrawal.step2.label.bic')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverBIC', value)}
							placeholder="Ex. BCNSSXXXX"
							type="text"
							value={bankDetails.receiverBIC}
						/> */}
						<p>{t('pages.withdrawal.step2.label.streetAndNumber')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverStreetAndNumber', value)}
							placeholder="Ex. Lublin 123"
							type="text"
							value={bankDetails.receiverStreetAndNumber}
						/>
						<p>{t('pages.withdrawal.step2.label.city')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverCity', value)}
							placeholder="Ex. New York City"
							type="text"
							value={bankDetails.receiverCity}
						/>
						<p>{t('pages.withdrawal.step2.label.postalCode')}</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverPostalCode', value)}
							placeholder="Ex. 9948838"
							type="text"
							value={bankDetails.receiverPostalCode}
						/>
					</>
				)}
				{/* {selectedAccountType === 'iban' && (
					<div>
						<p className="mt-5">{t('pages.withdrawal.step2.label.iban')} / IBAN</p>
						<Input
							onChange={(value) => handleBankDetailsChange('receiverIBAN', value)}
							placeholder="Ex. PL12345678910"
							type="text"
							value={bankDetails.receiverIBAN}
						/>

						{isVerified && (
							<>
								<p>
									{t('pages.withdrawal.step2.label.fullName')}
									<b>*</b>
								</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverFullName', value)}
									placeholder="Ex. John Doe"
									type="text"
									value={bankDetails.receiverFullName}
								/>
								<p>{t('pages.withdrawal.step2.label.bankName')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverBankName', value)}
									placeholder="Ex. Bank Of China"
									type="text"
									value={bankDetails.receiverBankName}
								/>
								<p>{t('pages.withdrawal.step2.label.bic')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverBIC', value)}
									placeholder="Ex. BCNSSXXXX"
									type="text"
									value={bankDetails.receiverBIC}
								/>
								<p>{t('pages.withdrawal.step2.label.streetAndNumber')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverStreetAndNumber', value)}
									placeholder="Ex. Lublin 123"
									type="text"
									value={bankDetails.receiverStreetAndNumber}
								/>
								<p>{t('pages.withdrawal.step2.label.city')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverCity', value)}
									placeholder="Ex. New York City"
									type="text"
									value={bankDetails.receiverCity}
								/>
								<p>{t('pages.withdrawal.step2.label.postalCode')}</p>
								<Input
									onChange={(value) => handleBankDetailsChange('receiverPostalCode', value)}
									placeholder="Ex. 9948838"
									type="text"
									value={bankDetails.receiverPostalCode}
								/>
							</>
						)}
					</div>
				)} */}
			</div>
		</>
	);
}
