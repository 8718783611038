import { z } from 'zod';

export const TranferSchema = (t) =>
	z.object({
		currency: z
			.string()
			.trim()
			.min(3, { message: t('errors.validations.currency') }),
		amount: z.number().min(1, { message: t('errors.validations.invalidAmount') }),
		customerNumber: z
			.string()
			.trim()
			.min(1, { message: t('errors.validations.customerNumber') }),
		customerName: z
			.string()
			.trim()
			.min(1, { message: t('errors.validations.customerName') }),
		reason: z
			.string()
			.trim()
			.min(1, { message: t('errors.validations.reason') }),
	});

export const SwapSchema = z
	.object({
		fromCurrency: z.string().trim().min(3, { message: 'Please select from currency!' }),
		amount: z.number().min(1, { message: 'Please enter a valid amount' }),
		toCurrency: z.string().trim().min(3, { message: 'Please select to currency!' }),
	})
	.refine((data) => data.fromCurrency !== data.toCurrency, {
		message: 'From currency and exchanged currency must be different!',
		path: ['toCurrency'],
	});

export const bankWithdrawSchema = z.object({
	bankName: z.string().trim().min(1, { message: 'Please select bank name!' }),
	bankAddress: z.string().trim().min(1, { message: 'Please select bank address!' }),
	bankCity: z.string().trim().min(1, { message: 'Please select bank city!' }),
	bankZipCode: z.string().trim().min(1, { message: 'Please select bank zipcode!' }),
	bankCountry: z.string().trim().min(1, { message: 'Please select bank country!' }),
	customerName: z.string().trim().min(1, { message: 'Please select account holder name!' }),
	swift: z.string().trim().min(1, { message: 'Please select bank swift/bic!' }),
	bankIBAN: z.string().trim().min(1, { message: 'Please select bank IBAN/Route!' }),
	amount: z.number().min(1, { message: 'Please enter a valid amount' }),
});

export const physicalCardApplySchema = z.object({
	city: z.string().trim().min(1, { message: 'Please enter a city!' }),
	street: z.string().trim().min(1, { message: 'Please enter a street!' }),
	number: z.string().trim().min(1, { message: 'Please select apartment/house Number!' }),
	postCode: z.string().trim().min(1, { message: 'Please select a postal code!' }),
	country: z.string().trim().min(1, { message: 'Please select a country!' }),
	method: z.string().trim().min(1, { message: 'Please select a shipping method!' }),
});

export const physicalCardApplySchema2 = z.object({
	country: z.string().trim().min(1, { message: 'Please select a country!' }),
	method: z.string().trim().min(1, { message: 'Please select a shipping method!' }),
	city: z
		.string()
		.min(1, 'City is required')
		.max(27, 'City name must be at most 27 characters')
		.regex(/^[a-zA-Z\s]+$/, 'City can only contain English letters and spaces'), // No special characters, only English letters and spaces
	street: z
		.string()
		.min(1, 'Street is required')
		.max(27, 'Street name must be at most 27 characters')
		.regex(/^[a-zA-Z0-9 ]+$/, 'Street can only contain English letters, numbers, and spaces'), // English letters, numbers, and spaces

	number: z
		.string()
		.min(1, 'Apartment/House Number is required')
		.max(27, 'Apartment/House Number must be at most 27 characters')
		.regex(/^[a-zA-Z0-9 ]+$/, 'Apartment/House Number can only contain English letters, numbers, and spaces'), // English letters, numbers, and spaces

	postCode: z
		.string()
		.min(1, 'Postal Code is required')
		.max(27, 'Postal Code must be at most 27 characters')
		.regex(/^[a-zA-Z0-9-]+$/, "Postal Code can only contain letters, numbers, and '-'"), // Letters, numbers, and dash
	// 	city: z
	//     .string()
	//     .min(1, "City is required")
	//     .max(27, "City name must be at most 27 characters")
	//     .regex(/^[a-zA-Z ]+$/, "City can only contain English letters and spaces"), // Allows spaces

	//   street: z
	//     .string()
	//     .min(1, "Street is required")
	//     .max(27, "Street name must be at most 27 characters")
	//     .regex(/^[a-zA-Z0-9 ]+$/, "Street can only contain English letters, numbers, and spaces"), // Allows spaces

	//   number: z
	//     .string()
	//     .min(1, "Apartment/House Number is required")
	//     .max(27, "Apartment/House Number must be at most 27 characters")
	//     .regex(/^[a-zA-Z0-9]+$/, "Apartment/House Number can only contain English letters and numbers"), // No spaces allowed

	//   postCode: z
	//     .string()
	//     .min(1, "Post Code is required")
	//     .max(27, "Post Code must be at most 27 characters")
	//     .regex(/^[a-zA-Z0-9-]+$/, "Post Code can only contain letters, numbers, and '-'"), // No spaces allowed
});

// const onlyLettersRegex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
// const noSpecialCharsRegex = /^[A-Za-z0-9\s-]+$/; // Allows letters, numbers, spaces, and hyphens (for postal code)
// const postCodeRegex = /^\d{2}-\d{3}$/; // Matches postal code format like "20-123"

// export const physicalCardApplySchema2 = z.object({
// 	city: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please enter a city!' })
// 		.max(27, { message: 'City name cannot exceed 27 characters!' })
// 		.regex(onlyLettersRegex, { message: 'City name can only contain letters and spaces!' }),

// 	street: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please enter a street!' })
// 		.max(27, { message: 'Street name cannot exceed 27 characters!' })
// 		.regex(onlyLettersRegex, { message: 'Street name can only contain letters and spaces!' }),

// 	number: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please enter an apartment/house number!' })
// 		.max(27, { message: 'Apartment/house number cannot exceed 27 characters!' })
// 		.regex(noSpecialCharsRegex, { message: 'Apartment/house number cannot contain special characters!' }),

// 	postCode: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please enter a postal code!' })
// 		.max(27, { message: 'Postal code cannot exceed 27 characters!' })
// 		.regex(postCodeRegex, { message: 'Postal code must follow the format XX-XXX (e.g., 20-123)!' }),

// 	country: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please select a country!' })
// 		.max(27, { message: 'Country name cannot exceed 27 characters!' })
// 		.regex(onlyLettersRegex, { message: 'Country name can only contain letters and spaces!' }),

// 	method: z.string().trim().min(1, { message: 'Please select a shipping method!' }),
// });

// const onlyLettersRegex = /^[A-Za-z\s]+$/;

// export const physicalCardApplySchema2 = z.object({
// 	city: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please enter a city!' })
// 		.regex(onlyLettersRegex, { message: 'City name can only contain letters and spaces!' }),

// 	street: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please enter a street!' })
// 		.regex(onlyLettersRegex, { message: 'Street name can only contain letters and spaces!' }),

// 	number: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please select apartment/house Number!' })
// 		.regex(/^\d+$/, { message: 'apartment/house Number must be numeric only!' }),

// 	postCode: z.string().trim().min(1, { message: 'Please select a postal code!' }).regex(/^\d+$/, { message: 'Postal code must be numeric only!' }),

// 	country: z
// 		.string()
// 		.trim()
// 		.min(1, { message: 'Please select a country!' })
// 		.regex(onlyLettersRegex, { message: 'Country name can only contain letters and spaces!' }),

// 	method: z.string().trim().min(1, { message: 'Please select a shipping method!' }),
// });
