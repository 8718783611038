import { useTranslation } from 'react-i18next';

import { Header } from '../../../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../../../components/navigation/accountDetailsSubHeader';
import { Input } from '../../../../../components/inputs/textInput';
import { useEffect, useState } from 'react';
import { getFeeByType } from '../../../../../services/fees';

// TODO move to const
const currencies = [
	{ code: 'EUR', flag: '🇪🇺' },
	{ code: 'USD', flag: '🇺🇸' },
	{ code: 'GBP', flag: '🇬🇧' },
	{ code: 'CHF', flag: '🇨🇭' },
	{ code: 'AED', flag: '🇦🇪' },
	{ code: 'AUD', flag: '🇦🇺' },
	{ code: 'CAD', flag: '🇨🇦' },
	{ code: 'HUF', flag: '🇭🇺' },
	{ code: 'JPY', flag: '🇯🇵' },
	{ code: 'CZK', flag: '🇨🇿' },
	{ code: 'DKK', flag: '🇩🇰' },
	{ code: 'NOK', flag: '🇳🇴' },
	{ code: 'SEK', flag: '🇸🇪' },
	{ code: 'RON', flag: '🇷🇴' },
	{ code: 'BGN', flag: '🇧🇬' },
	{ code: 'TRY', flag: '🇹🇷' },
	{ code: 'ILS', flag: '🇮🇱' },
	{ code: 'MXN', flag: '🇲🇽' },
	{ code: 'ZAR', flag: '🇿🇦' },
	{ code: 'CNY', flag: '🇨🇳' },
];

export default function IBANWithdrawalThirdStep({ paymentInfo, currencyHandler, amountHandler, reasonHandler }) {
	const langDir = localStorage.getItem('languageDir');
	const { t } = useTranslation();

	const [swiftFees, setSwiftFees] = useState('');
	const [sepaFees, setSepaFees] = useState('');

	const getSwiftFees = async () => {
		const feesResponse = await getFeeByType({ type: 'iban_withdraw_swift' });
		let feeText = '';
		feesResponse.data.forEach((fee) => {
			if (fee.kind === 'percentage' && fee.cost > 0) {
				feeText += fee.cost + '%';
			} else {
				if (feeText) feeText += ' + ' + fee.cost + '€';
				else feeText = fee.cost + '€';
			}
		});

		setSwiftFees(feeText);
	};

	const getSepaFees = async () => {
		const feesResponse = await getFeeByType({ type: 'iban_withdraw_sepa' });
		let feeText = '';
		feesResponse.data.forEach((fee) => {
			if (fee.kind === 'percentage' && fee.cost > 0) {
				feeText += fee.cost + '%';
			} else {
				if (fee.cost <= 0) return;
				if (feeText) feeText += ' + ' + fee.cost + '€';
				else feeText = fee.cost + '€';
			}
		});

		setSepaFees(feeText);
	};

	useEffect(() => {
		getSwiftFees();
		getSepaFees();
	}, []);

	return (
		<>
			<div dir={langDir}>
				<Header title={t('pages.withdrawal.header.iban')} backNavigation={() => window.location.reload()} />

				<AccountDetailsSubHeader />
				<p>{t('pages.withdrawal.step3.label.currency')}</p>

				<Input disabled={true} value="EUR" />

				<p>{t('pages.withdrawal.step3.label.amount')} (€)</p>
				<Input
					placeholder="0.00"
					type="number"
					value={paymentInfo.amount}
					onChange={(value) => {
						if (value.length > 15) return;
						amountHandler(value);
					}}
					inputMode="numeric"
					pattern="[0-9]"
				/>
				<p>{t('pages.withdrawal.step3.label.reason')}</p>
				<Input
					onChange={(value) => {
						reasonHandler(value);
					}}
					placeholder="Ex. Food"
					type="text"
					value={paymentInfo.reason}
				/>

				<div className="font-bold">
					<p className="text-red">Fees will be applied:</p>
					<p>SEPA Transfer: {sepaFees}</p>
					<p>SWIFT Transfer: {swiftFees}</p>
				</div>
				{/* <div>
					<p>
						You will be charged:
						{' €' + (amount && amount > 0 ? numeral(Math.round(parseFloat(amount) + getAmountWithFees(amount))).format('0,0') : 0)}
					</p>
					<p>You will be receive: {amount && amount > 0 ? numeral(exchangedAmount).format('0,0') + ' ' + currency : 0}</p>
					{wireType === 'SWIFT' && <p className="text-red mt-5">Other fx fees may be applied by the receiver bank</p>}
				</div> */}
			</div>
		</>
	);
}
