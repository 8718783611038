import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import PaytoraWithdrawalFirstStep from './paytoraFirstStep';

import { paytoraWithdrawalSchema } from '../../../../../utils/validations/withdraw/withdraw-schema';

import useValidation from '../../../../../hooks/useValidation';

import { ErrorBubble } from '../../../../../components/utils/errorBubble';
import { PrimaryButton } from '../../../../../components/buttons/primary-button';
import PaytoraWithdrawalSecondStep from './paytoraSecondStep';
import { PageLogo } from '../../../../../components/utils/pageLogo';
import { CustomModal } from '../../../../../components/modals/customModal';
import { withdrawalAPI } from '../../../../../services/ibans';
import { PincodeModal } from '../../../../../components/modals/pincodeModal';
import Loader from '../../../../utils/loader';

export const forbiddenWords = ['crypto', 'exchange', 'forex', 'gambling', 'casino'];

export default function PaytoraWithdrawalPage() {
	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const { validationErrMsg, validate } = useValidation(paytoraWithdrawalSchema(t));

	const [amount, setAmount] = useState(1000);
	const [reason, setReason] = useState('');

	const [isLoading, setIsLoading] = useState(false);

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	const [step, setStep] = useState(1);

	const [currency, setCurrency] = useState('KHR');

	const [bankDetails, setBankDetails] = useState({
		receiverFullName: '',
		receiverIBAN: '',
		receiverStreetAndNumber: '',
		receiverPostalCode: '',
		receiverCity: '',
		receiverBIC: '',
		receiverBankName: '',
		receiverBankBranch: '',
	});

	const handleBankWire = async (pincode) => {
		const data = {
			bankDetails,
			amount,
			currency,
			reason,
			pincode,
		};

		if (!amount || amount <= 0) return setErrMsg(t('errors.validations.invalidAmount'));

		if (!reason) return setErrMsg(t('errors.validations.reason'));

		if (forbiddenWords.some((word) => bankDetails.receiverFullName.toLowerCase().includes(word)))
			return setErrMsg('We are not supporting this receiver!');

		if (!isLoading) {
			setIsLoading(true);
			const response = await withdrawalAPI(data, 'paytora');

			if (!response.isSuccess) {
				setIsLoading(false);
				setReason('');
				return setErrMsg(response.error ?? t('errors.server.GENERAL_ERROR'));
			}
		}

		setIsLoading(false);

		setIsSuccessModalOpen(true);
	};

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 h-screen flex flex-col justify-between">
			{/** STEP ONE */}
			{step === 1 && (
				<>
					<PaytoraWithdrawalFirstStep
						bankDetails={bankDetails}
						handleBankDetailsChange={(field, value) => {
							if (currency === 'CNY' && field !== 'receiverIBAN') {
								// Validate if the input contains only Chinese characters
								const isValidChinese = /^[\u4e00-\u9fff\s]+$/.test(value);
								if (!isValidChinese && value !== '') {
									setErrMsg('information must be in Chinese.');
									return; // Stop update if invalid
								} else setErrMsg(''); // Clear the error if valid
							}

							// Update the corresponding field in bank details
							setBankDetails((data) => ({ ...data, [field]: value }));
						}}
						currencyHandler={(value) => {
							setBankDetails({
								receiverFullName: '',
								receiverIBAN: '',
								receiverStreetAndNumber: '',
								receiverPostalCode: '',
								receiverCity: '',
								receiverBIC: '',
								receiverBankName: '',
								receiverBankBranch: '',
							});

							setErrMsg('');

							setCurrency(value);
						}}
					/>
					{currency === 'CNY' && <p className="mt-2 mb-2 text-red">{t('pages.withdrawal.step2.label.onlyChinese')}</p>}

					<div>
						{errMsg && <ErrorBubble error={errMsg} />}
						{validationErrMsg && <ErrorBubble error={validationErrMsg} />}

						<PrimaryButton
							text={t('buttons.next')}
							className="mb-[10px]"
							textColor="text-white"
							onClick={async () => {
								const isValid = validate(bankDetails);
								if (!isValid) return;

								setStep(step + 1);
							}}
						/>
					</div>
				</>
			)}
			{step === 2 && (
				<>
					<PaytoraWithdrawalSecondStep amountHandler={setAmount} reasonHandler={setReason} paymentInfo={{ amount, currency }} />
					<div className="flex flex-col justify-center">
						<PageLogo />
					</div>
					<div>
						{errMsg && <ErrorBubble error={errMsg} />}
						<PrimaryButton
							text={t('buttons.send')}
							className="mb-[10px]"
							iconPath="icons/arrow_out_white.svg"
							textColor="text-white"
							onClick={() => setIsPincodeModalOpen(true)}
						/>
					</div>
				</>
			)}
			<CustomModal
				isOpen={isSuccessModalOpen}
				onClose={() => setIsSuccessModalOpen(false)}
				title={t('modals.withdrawal.title')} //'Your withdraw action has been submitted'
				subTitle={t('modals.withdrawal.subTitle')} //You will receive a notification while process completed
				children={
					<div>
						<p className="pb-5 text-red font-bold">{t('modals.withdrawal.message')} </p>
						<div className="text-center">
							<PrimaryButton
								onClick={async () => {
									setIsSuccessModalOpen(false);
									window.location.reload();
								}}
								text={t('buttons.close')}
								textColor="text-white"
							/>
						</div>
					</div>
				}
			/>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => {
					setIsPincodeModalOpen(false);
				}}
				onAction={async (pincode) => {
					await handleBankWire(pincode);
					setIsPincodeModalOpen(false);
				}}
			/>
		</div>
	);
}
