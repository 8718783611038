import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const currenciesBgColors = ['bg-blue', 'bg-green', 'bg-purple', 'bg-black'];

export const AccountBalance = ({ userWallets, selectedWallet = null }) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	return selectedWallet ? (
		<div
			onClick={() =>
				navigate('/transactions/paytora', {
					state: { currency: selectedWallet.currency, symbol: selectedWallet.symbol, balance: numeral(selectedWallet.balance).format('0,0.00') },
				})
			}
			className={`bg-darkGold p-2 mt-[10px] rounded-[10px] w-full text-white me-[10px]`}
			key={selectedWallet.currency}
		>
			<div className="flex justify-between">
				<div className="flex space-x-2 items-center">
					<h1 className="font-bold text-md">
						{t('pages.dashboard.accountBalance.paytora')} {selectedWallet.currency} {t('pages.dashboard.accountBalance.account')}
					</h1>
					<p className="text-sm">{t('pages.dashboard.accountBalance.balance')}</p>
				</div>

				<div className="flex flex-col items-end">
					<h2 className="text-lg">{selectedWallet.symbol + ' ' + numeral(selectedWallet.balance).format('0,0.00')}</h2>
				</div>
			</div>
		</div>
	) : (
		<div className="grid grid-cols-1 w-full" dir={langDir}>
			{userWallets
				// .filter((account) => account.currency !== 'GBP')
				.map((account, index) => (
					<div
						onClick={() =>
							navigate('/transactions/paytora', {
								state: { currency: account.currency, symbol: account.symbol, balance: numeral(account.balance).format('0,0.00') },
							})
						}
						className={`${currenciesBgColors[index] ?? 'bg-darkGold'} p-2 mt-[10px] rounded-[10px] w-full text-white me-[10px]`}
						key={account.currency}
					>
						<div className="flex justify-between">
							<div className="flex space-x-2 items-center">
								{langDir === 'rtl' ? (
									<h1 className="font-bold text-md me-5">
										{t('pages.dashboard.accountBalance.account')} {t('pages.dashboard.accountBalance.paytora')} {account.currency}
									</h1>
								) : (
									<h1 className="font-bold text-md me-5">
										{t('pages.dashboard.accountBalance.paytora')} {account.currency} {t('pages.dashboard.accountBalance.account')}
									</h1>
								)}
								<p className="text-sm">{t('pages.dashboard.accountBalance.balance')}</p>
							</div>

							<div className="flex flex-col items-end">
								<h2 className="text-lg">{account.symbol + ' ' + numeral(account.balance).format('0,0.00')}</h2>
							</div>
						</div>
					</div>
				))}
		</div>
	);
};
