import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../buttons/primary-button';

import SelectInput from '../inputs/selectInput';
import { CustomModal } from '../modals/customModal';

const languageOptions = [
	{ value: 'en', label: '🇺🇸 EN' },
	{ value: 'he', label: '🇮🇱 HE' },
	// { value: 'ch', label: '🇨🇳 CH' },
];

export const LanguageSwitcher = ({ logoBlack = true }) => {
	const { t } = useTranslation();

	const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

	const { i18n } = useTranslation();

	const changeLanguage1 = (lng) => {
		window.location.reload();
		i18n.changeLanguage(lng);
		localStorage.setItem('language', lng);
		localStorage.setItem('languageDir', lng === 'he' ? 'rtl' : 'ltr');
	};

	const langDir = localStorage.getItem('languageDir');

	const currentLanguage = languageOptions.find((option) => option.value === i18n.language);

	return (
		<div>
			<div className={`${langDir === 'rtl' && 'me-[38px]'} mt-[2px]`} onClick={() => setIsLanguageModalOpen(true)}>
				<img src={`/assets/icons/${logoBlack ? 'language_black.svg' : 'language.svg'}`} alt="language" />
			</div>
			<CustomModal
				isOpen={isLanguageModalOpen}
				onClose={() => setIsLanguageModalOpen(false)}
				title={t('pages.language.title')}
				subTitle={t('pages.language.subTitle')}
				children={
					<div className="flex flex-col justify-between h-full">
						<SelectInput
							name="lang-select"
							onChange={(value) => changeLanguage1(value)}
							defaultValue={{ value: i18n.language, label: currentLanguage.label }}
							options={languageOptions}
							className="w-full bg-white"
						/>
						<PrimaryButton
							onClick={() => {
								setIsLanguageModalOpen(false);
							}}
							text={t('buttons.close')}
							textColor="text-white"
						/>
					</div>
				}
			/>
		</div>
	);
};
