import { useTranslation } from 'react-i18next';

import { Header } from '../../../../../components/navigation/header';
import { AccountDetailsSubHeader } from '../../../../../components/navigation/accountDetailsSubHeader';
import { Input } from '../../../../../components/inputs/textInput';
import { useEffect, useState } from 'react';
import { getFeeByType } from '../../../../../services/fees';
import numeral from 'numeral';
import { swapCurrencyAPI } from '../../../../../services/swap';

// TODO move to const

export default function PaytoraWithdrawalSecondStep({ paymentInfo, amountHandler, reasonHandler }) {
	const langDir = localStorage.getItem('languageDir');
	const { t } = useTranslation();

	const [pTotalFee, setPtotalFee] = useState(0);
	const [totalFee, setTotalFee] = useState(0);

	const [exchangedAmount, setExchangedAmount] = useState(0);

	const getFees = async () => {
		const feesResponse = await getFeeByType({ type: 'iban_withdraw_instant' });

		feesResponse.data.forEach((fee) => {
			if (fee.kind === 'percentage') setPtotalFee(fee.cost);
			else setTotalFee(fee.cost);
		});
	};

	const getRates = async (amount) => {
		if (amount && amount > 0) {
			const valueAfterFees = amount - calculateTotalFee(amount);
			if (valueAfterFees <= 0) return;

			const response = await swapCurrencyAPI({ fromCurrency: 'EUR', toCurrency: paymentInfo.currency, amount: valueAfterFees, withfee: false });

			if (response.isSuccess) setExchangedAmount(response.data);
		}
	};

	const calculateTotalFee = (amount) => {
		const percentageFee = amount * (pTotalFee / 100);
		const fixedFee = totalFee;

		const calculatedFee = percentageFee + fixedFee;

		return calculatedFee;
	};

	useEffect(() => {
		getFees();
		getRates(1000);
	}, []);

	return (
		<>
			<div dir={langDir}>
				<Header title={t('pages.withdrawal.header.paytora')} backNavigation={() => window.location.reload()} />

				<AccountDetailsSubHeader />
				<p>{t('pages.withdrawal.step3.label.currency')}</p>

				<Input disabled={true} value={paymentInfo.currency} />

				<p>{t('pages.withdrawal.step3.label.amount')} (€)</p>
				<Input
					placeholder="0.00"
					type="number"
					value={paymentInfo.amount}
					onChange={async (value) => {
						if (value.length > 15) return;
						amountHandler(value);
						await getRates(value);
					}}
					inputMode="numeric"
					pattern="[0-9]"
				/>
				<p className="text-sm"> {t('pages.withdrawal.step3.label.minimum.chargedAmount')}</p>

				<p>{t('pages.withdrawal.step3.label.reason')}</p>
				<Input
					onChange={(value) => {
						reasonHandler(value);
					}}
					placeholder="Ex. Food"
					type="text"
					value={paymentInfo.reason}
				/>

				<div>
					<p>
						You will be charged:
						{' €' + paymentInfo.amount}
					</p>
					<p>
						You will be receive:
						{paymentInfo.amount && paymentInfo.amount > 0
							? ' ~ ' + numeral(exchangedAmount).format('0,0') + ' ' + paymentInfo.currency
							: 0 + ' ' + paymentInfo.currency}
					</p>
				</div>

				<div className="font-bold mt-3">
					<p className="text-red">Fees will be applied:</p>
					<p>INSTANT Transfer: {pTotalFee + '%'}</p>
				</div>
			</div>
		</>
	);
}
