import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useValidation from '../../hooks/useValidation';

import { getUserCardsAPI } from '../../services/cards';
import { getFeeByType } from '../../services/fees';
import { cardToCardTransfer, cardToCardVerify } from '../../services/card-to-card';

import Loader from '../utils/loader';
import { formatCardNumber } from '../../utils/functions/general';
import { Card2CardVerifySchema } from '../../utils/validations/cards/cards_schema';

import { PrimaryButton } from '../../components/buttons/primary-button';
import { Input } from '../../components/inputs/textInput';
import SelectInput from '../../components/inputs/selectInput';
import { Header } from '../../components/navigation/header';
import { ErrorBubble } from '../../components/utils/errorBubble';
import { PincodeModal } from '../../components/modals/pincodeModal';
import { AccountDetailsSubHeader } from '../../components/navigation/accountDetailsSubHeader';

export default function CardToCard() {
	const { t } = useTranslation();

	const langDir = localStorage.getItem('languageDir');

	const navigate = useNavigate();

	const { validationErrMsg, validate } = useValidation(Card2CardVerifySchema(t));

	const [amount, setAmount] = useState();

	const [cards, setCards] = useState([]);

	const [selectedCard, setSelectedCard] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const [totalFee, setTotalFee] = useState(null);
	const [pTotalFee, setPtotalFee] = useState(null);

	const [displayCardNumber, setDisplayCardNumber] = useState('****************');
	const [displayCustomerNumber, setDisplayCustomerNumber] = useState('*****');

	const [isPincodeModalOpen, setIsPincodeModalOpen] = useState(false);

	const [cardData, setCardData] = useState({
		cardNumber: '',
		customerNumber: '',
	});

	const [errMsg, setErrMsg] = useState('');

	const transferHandler = async (pincode) => {
		if (!isLoading) {
			setIsLoading(true);

			const response = await cardToCardTransfer({
				amount,
				currency: 'EUR',
				senderCardId: selectedCard,
				pincode,
				receiverCardNumber: cardData.cardNumber,
				customerNumber: cardData.customerNumber,
			});
			if (!response.isSuccess) {
				setErrMsg(response.error);
				setIsLoading(false);
				setSelectedCard(cards[0]._id);
				return;
			}
		}
		setIsLoading(false);
		navigate('/success');
	};

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getUserCardsAPI();
			const availableCards = response.cards.filter((card) => card.status === 'A');
			setCards(availableCards);
			setSelectedCard(availableCards.length > 0 ? availableCards[0]._id : null);
			setIsLoading(false);
			const feesResponse = await getFeeByType({ type: 'card_to_card' });
			if (feesResponse.isSuccess) {
				feesResponse.data.forEach((fee) => {
					if (fee.kind === 'percentage') setPtotalFee(fee.cost);
					else setTotalFee(fee.cost);
				});
			}
		};

		fetchData();
	}, []);

	return isLoading ? (
		<Loader />
	) : (
		<div className="p-5 flex flex-col justify-between h-screen" dir={langDir}>
			<div>
				<Header title={t('pages.cardToCard.header')} />

				<AccountDetailsSubHeader
					showBalance={false}
					showAmount={true}
					amount={totalFee > 0 && amount > 0 && amount - (amount * pTotalFee) / 100 - totalFee}
				/>
				<h1 className="text-lg font-bold mb-[10px]">{t('pages.cardToCard.title')}</h1>
				<div className="bg-black text-white rounded-[10px] p-5 text-md">
					<p className="font-bold mb-2">{t('pages.cardToCard.label.paytoraMember')}</p>
					<p className="text-darkGray mb-2" dir="ltr">
						{formatCardNumber(displayCardNumber)}
					</p>
					<p className="text-darkGray">
						{t('pages.cardToCard.label.paytoraAccountNumber')} {displayCustomerNumber.toUpperCase()}
					</p>
				</div>

				{cards.length === 0 ? (
					<p className="text-center font-bold mt-[20%]">{t('pages.cardToCard.label.noCards')}</p>
				) : (
					<div>
						<p className="text-lg font-bold mt-5">{t('pages.cardToCard.cardDetails.title')}</p>
						<div className="border border-lightGray rounded-[10px] mt-[10px] p-[20px] mb-[20px]">
							<p className="text-gradient text-center text-md mb-[20px]">{t('pages.cardToCard.cardDetails.warning')}</p>

							<Input
								placeholder={t('inputs.placeholders.cardNumber')}
								type="number"
								value={cardData.cardNumber}
								onChange={(value) => {
									if (value.length > 16) return;
									setDisplayCardNumber(value === '' ? '****************' : value);
									setCardData((data) => ({ ...data, cardNumber: value }));
								}}
								inputMode="numeric"
								pattern="[0-9]"
							/>
							<Input
								onChange={(value) => {
									setDisplayCustomerNumber(value === '' ? '*****' : value);
									setCardData((data) => ({ ...data, customerNumber: value }));
								}}
								placeholder={t('inputs.placeholders.customerNumber')}
								type="text"
								value={cardData.customerNumber}
							/>
						</div>

						<Input
							name="amount"
							placeholder={t('inputs.placeholders.transferAmount')}
							type="number"
							value={amount}
							onChange={(value) => {
								if (value.length > 8) return;
								setAmount(value);
							}}
							inputMode="numeric"
							pattern="[0-9]"
						/>
						<label htmlFor="cards" className="text-lg font-bold">
							{t('pages.cardToCard.paymentMethod.title')}
						</label>
						<SelectInput
							name="cards"
							onChange={setSelectedCard}
							options={cards.map((card) => ({ value: card._id, label: card.pan + ' - €' + card.balance }))}
							defaultValue={{ value: cards[0]._id, label: cards[0].pan + ' - €' + cards[0].balance }}
						/>
						<div className="mt-1 mb-2 text-red">
							{totalFee && pTotalFee && (
								<p>
									{t('pages.cardToCard.label.fees')} {pTotalFee}% + €{totalFee}
								</p>
							)}
						</div>
					</div>
				)}
			</div>
			<div className="pb-5">
				{errMsg && <ErrorBubble error={errMsg} />}
				{validationErrMsg && <ErrorBubble error={validationErrMsg} />}
				{cards.length > 0 && (
					<PrimaryButton
						text={t('buttons.transfer')}
						textColor="text-white"
						onClick={() => {
							// TODO: add validation for selected card?? by default its updated.
							const isValid = validate({ cardNumber: cardData.cardNumber, customerNumber: cardData.customerNumber });

							if (!isValid) return setErrMsg('');

							if (!amount || amount < 0) return setErrMsg(t('errors.validations.invalidAmount'));

							setIsPincodeModalOpen(true);
						}}
					/>
				)}
			</div>

			<PincodeModal
				isOpen={isPincodeModalOpen}
				onClose={() => setIsPincodeModalOpen(false)}
				onAction={async (pincode) => {
					await transferHandler(pincode);
					setIsPincodeModalOpen(false);
				}}
			/>
		</div>
	);
}
